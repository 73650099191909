import * as React from "react"
import {Box, Stack} from "@mui/material";
import {StaticImage} from "gatsby-plugin-image";
import Typography from "@mui/material/Typography";

interface Props {
  title: string
  subtitle: string
}
const BouwtypeTitle = ({title, subtitle}: Props) => {
  return (
    <Stack
      direction={'row'}
      spacing={2}
      alignItems={'start'}
    >
      <StaticImage
        src={'../../images/icon.png'}
        width={46}
        alt={''}
      />
      <Box>
        <Typography
          variant={'h2'}
          fontFamily={'Unna'}
        >
          {title}
        </Typography>
        <Typography
          variant={'h5'}
          fontFamily={'Finlandica'}
          color={'primary'}
          textTransform={'uppercase'}
        >
          {subtitle}
        </Typography>
      </Box>
    </Stack>
  )
}

export default BouwtypeTitle
