import * as React from "react"
import Container from "@mui/material/Container";
import {
  Box,
  Button,
  Grid,
  List,
  ListItemIcon,
  ListItemText,
  ListItem, Stack,
} from "@mui/material";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import {sectionSpacing} from "../theme";
import Typography from "@mui/material/Typography";
import {graphql, Link} from "gatsby";

import Prijs from "../components/prijs";
import Bouwnummers from "../components/bouwnummers";
import {Bouwnummer, Bouwtype} from "../graphqlTypes";
// @ts-ignore
import Bullit from '../images/bullit.svg';
import BouwtypeTitle from "../components/bouwtype/title";
import BouwnummerModal from "../components/bouwnummer/modal";
import {useState} from "react";


interface WoningProps {
  bouwnummer: Bouwnummer
}

export const Woning = ({bouwnummer}: WoningProps) => {
  const [bouwnummerOpen, setBouwnummerOpen] = useState(false)
  const image = bouwnummer.hoofdfoto && getImage(bouwnummer.hoofdfoto.localFile)
  return (
    <Stack spacing={4} alignItems={'start'}>
      <Typography variant={'h4'}>
        Bouwnummer {bouwnummer.diversen.diversen.bouwnummer}
      </Typography>
      <Typography
        textTransform={'uppercase'}
        variant={'h5'}
        color={'primary'}
        fontWeight={'bold'}
        sx={{
          marginY: 3
        }}
      >
        <Prijs prijs={bouwnummer.financieel.overdracht.koopprijs || 0}/> v.o.n.
      </Typography>
      {image &&
          <GatsbyImage
              alt={''}
              image={image}
              style={{
                width: '100%'
              }}
          />
      }
      <List
        dense={true}
        sx={{
          flexGrow: 100
        }}
      >
        {bouwnummer.children[0]?.frontmatter.kenmerken.map((item: string, index: number) =>
          <ListItem key={index}>
            <ListItemIcon>
              <StaticImage src={'../images/bullit.svg'} alt={''}/>
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={<Typography fontFamily={'Finlandica'} fontWeight={'bold'}>{item}</Typography>}
            />
          </ListItem>
        )}
      </List>
      <Button
        onClick={() => setBouwnummerOpen(true)}
        variant={'contained'}
        color={'primary'}
        size={'large'}
      >
        Bekijk woning
      </Button>
      <BouwnummerModal
        bouwnummer={bouwnummerOpen ? bouwnummer : null}
        handleClose={() => setBouwnummerOpen(false)}
      />
    </Stack>
  )
}

interface WoningenProps {
  title: string
  subtitle: string
  text: string
  woningen: Bouwnummer[]
}

const Woningen = ({title, subtitle, text, woningen}: WoningenProps) => {
  return (
    <>
      <Box
        marginY={sectionSpacing}
      >
        <Grid
          container
          spacing={4}
          alignItems={'center'}
        >
          <Grid item xs={12} md={6}>
            <BouwtypeTitle title={title} subtitle={subtitle} />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            {text}
          </Grid>
        </Grid>
      </Box>
      <Box
        marginY={sectionSpacing}
      >
        <Grid
          container
          spacing={10}
        >
          {woningen.map(woning =>
            <Grid
              item
              xs={12}
              md={6}
              key={woning.id}
            >
              <Woning bouwnummer={woning}/>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}

interface WoningtypeProps {
  bouwtype: Bouwtype
}

export const Woningtype = ({bouwtype}: WoningtypeProps) => {
  const prijsVanaf = bouwtype.algemeen?.koopaanneemsomVanaf
  const image = bouwtype.hoofdfoto && getImage(bouwtype.hoofdfoto.localFile)
  return (
    <Stack spacing={4} alignItems={'start'}>
      <Box>
        <Typography variant={'h4'}>
          {bouwtype.algemeen.omschrijving}
        </Typography>
        {prijsVanaf &&
            <Typography
                textTransform={'uppercase'}
                variant={'h5'}
                color={'primary'}
                fontWeight={'bold'}
                sx={{
                  marginY: 3
                }}
            >
                v.a. <Prijs prijs={prijsVanaf || 0}/> v.o.n.
            </Typography>
        }
      </Box>
      {image &&
          <GatsbyImage
              alt={''}
              image={image}
              style={{
                width: '100%'
              }}
          />
      }
      <Stack
        direction={'row'}
        width={'100%'}
        borderTop={1}
        borderBottom={1}
        paddingY={2}
        borderColor={'primary.main'}
      >
        <Typography marginRight={2}>
          Bouwnr.
        </Typography>
        <Box>
          <Bouwnummers bouwnummers={bouwtype.bouwnummers || []}/>
        </Box>
      </Stack>
      <List
        dense={true}
        sx={{
          flexGrow: 100
        }}
      >
        {bouwtype.children[0]?.frontmatter.kenmerken.map((item: string, index: number) =>
          <ListItem key={index}>
            <ListItemIcon>
              <StaticImage src={'../images/bullit.svg'} alt={''}/>
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={<Typography fontFamily={'Finlandica'}>{item}</Typography>}
            />
          </ListItem>
        )}
      </List>
      <Button
        component={Link}
        to={`/woningen/${bouwtype.fields.slug}`}
        variant={'contained'}
        color={'primary'}
        size={'large'}
      >
        Bekijk {bouwtype.algemeen.omschrijving === 'Vrijstaande woning' ? 'woning' : 'appartement'}
      </Button>
    </Stack>
  )
}

interface WoningtypesProps {
  title: string
  subtitle: string
  text: string
  bouwtypes: Bouwtype[]
}

const Woningtypes = ({title, subtitle, text, bouwtypes}: WoningtypesProps) => {
  return (
    <>
      <Box
        marginY={sectionSpacing}
      >
        <Grid
          container
          spacing={10}
          alignItems={'center'}
        >
          <Grid item xs={12} md={6}>
            <BouwtypeTitle title={title} subtitle={subtitle} />
          </Grid>
          <Grid item xs={12} md={6}>
            {text}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid
          container
          spacing={10}
        >
          {bouwtypes.map(bouwtype =>
            <Grid
              item
              xs={12}
              md={6}
              key={bouwtype.id}
            >
              <Woningtype bouwtype={bouwtype}/>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}

interface Props {
  data: PageQueryData
}
export default ({data}: Props) => {
  const vrijstaandeWoningen = data.allBouwtype?.nodes.find(bouwtype => bouwtype.algemeen.omschrijving === 'Vrijstaande woning')
  return (
    <article>
      <StaticImage
        src="../images/impressies/2112_CAM5_Ontwikkelveld_zeewolde.jpg"
        alt="header"
        layout={'fullWidth'}
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          maxHeight: 800,
        }}
      />
      <Box
        paddingY={sectionSpacing}
        textAlign={'center'}
        fontWeight={'bold'}
        sx={{backgroundColor: 'common.white'}}
      >
        <Typography
          variant={'h3'}
        >
          <Box component={'span'} color={'primary.main'}>3 Vrijstaande woningen</Box> en <br />
          <Box component={'span'} color={'primary.main'}>15 appartementen</Box> aan het water
        </Typography>
      </Box>
      <Box
        paddingY={sectionSpacing}
        id={'vrijstaande-woningen'}
      >
        <Container maxWidth={'lg'}>
          {vrijstaandeWoningen &&
              <Woningen
                  title={"De Brugwachter"}
                  subtitle={"3 vrijstaande woningen"}
                  woningen={vrijstaandeWoningen.bouwnummers || []}
                  text={'De 3 vrijstaande woningen met tuin op het zuidwesten, zijn voorzien van 2 parkeerplaatsen op eigen terrein. Ook hebben de woningen een eigen aanlegsteiger. Hiermee heb je direct toegang tot de Blauwe Diamant en ben je in mum van tijd varend op het Wolderwijd.'}
              />
          }
        </Container>
      </Box>
      <Box
        paddingY={sectionSpacing/2}
        id={'appartementen'}
        sx={{
          backgroundColor: 'common.white'
        }}
      >
        <Container maxWidth={'lg'}>
          <Woningtypes
            title={"De Sluiswachter"}
            subtitle={"15 appartementen"}
            bouwtypes={data.allBouwtype.nodes.filter(bouwtype => bouwtype.algemeen.omschrijving !== 'Vrijstaande woning')}
            text={'De 15 appartementen met buitenruimte op het zuidwesten hebben een eigen parkeerplaats en een gezamenlijke aanlegsteiger. Op de bovenste verdieping bevinden zich 4 leuke studio’s met fenomenaal uitzicht op de sluis De Spieghel. De appartementen hebben een gezamenlijk ruim terras op het zuidwesten aan het waterplein.'}
          />
        </Container>
      </Box>
    </article>
  )
}

interface PageQueryData {
  allBouwtype: {
    nodes: Bouwtype[]
  }
}

export const query = graphql`
    query {
        allBouwtype {
            nodes {
                children {
                  id
                  ... on MarkdownRemark {
                    id
                    frontmatter {
                      intro
                      kenmerken
                    }
                    html
                  }
                }
                id
                fields {
                    slug
                }
                algemeen {
                    omschrijving
                    koopaanneemsomVanaf               
                }
                bouwnummers {
                    ...Bouwnummer
                }
                hoofdfoto {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                layout: FULL_WIDTH,
                                aspectRatio: 1.468,
                            )
                        }
                    }
                    omschrijving
                }
                ...BouwtypeSpecificaties
            }
        }
    }
`
